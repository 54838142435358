const onLoadCallsByHeapId = [
    'GetFeatures',
    'GetUserProfile',
    'GetCamFooter',
    'PolicyNotFoundSingleBillingAccount',
    'Alert|VerifyPolicies',
    'PostProcessFailure'
];

const billingCallsHeapId = [
    'GetBilling',
    'GetAccountOptions',
    'GetBillingDocuments',
    'GetBillingDocumentUrl',
    'UpdateAccountOptions',
    'GetSecureKey',
    'getBillStatements',
    'UpdateAdjustCurrentBillDate',
    'DeleteCreditCardUserProfile'
];

const policyCallsHeapId = [
    'GetPolicies',
    'GetAllPolicies',
    'GetPolicyDocuments',
    'GetPolicyDocumentUrl',
    'GetVinResponse',
    'OrderDocuments',
    'GetCDIPolies',
    'PolicyProfileStatusCheck',
    'PolicyVerify',
    'GetPolicy',
    'UpdateName',
    'UpdateAddress',
    'LegacyAddDriver',
    'UpdateDriver',
    'RemoveDriver',
    'PolicyChangePostWorkItem',
    'PolicyChangeGetWorkItems',
    'SearchCustomer',
    'EnableProactiveChat'
];

const esignCallsHeapId = [
    'GetEsignSearchResult',
    'GetEsignSessionUrls',
    'GetEsignPolicies'
];

const claimCallsHeapId = [
    'GetClaimsDetails'
];

const lowmileageCallsHeapId = [
    'getLowMileageVehicles',
    'getLowMileageCode',
    'setLowMileageResponse'
];

const communicationCallsHeapId = [
    'GetPaperlessBilling',
    'UpdatePaperlessPolicy',
    'GetOptInSMSStatus',
    'UpdateOptInSMS'
 ];

 const modalCallsHeapId = [
    'PaperlessTandCsForm',
 ]

const profileCallsHeapId = [
    'ChangeEmail',
    'ChangePassword'
];

const paymentCallsHeapId = [
    'InitiateInstrument'
];

const homepageHeaderAlertHeapId = [
];

const documentHeaderAlertHeapId = [
];

const billingAppAlertsHeapId = [
    'SingleCA'
];

const pageSpecificHeapIds = {
    homepage: onLoadCallsByHeapId.concat(billingCallsHeapId, policyCallsHeapId, claimCallsHeapId, esignCallsHeapId, modalCallsHeapId),
    billing: onLoadCallsByHeapId.concat(billingCallsHeapId, policyCallsHeapId, communicationCallsHeapId, billingAppAlertsHeapId),
    policy: onLoadCallsByHeapId.concat(billingCallsHeapId, policyCallsHeapId),
    esign: onLoadCallsByHeapId.concat(esignCallsHeapId),
    documents: onLoadCallsByHeapId.concat(policyCallsHeapId, claimCallsHeapId, esignCallsHeapId),
    profile: onLoadCallsByHeapId.concat(profileCallsHeapId, policyCallsHeapId),
    lowMileage: onLoadCallsByHeapId.concat(lowmileageCallsHeapId),
    communication: onLoadCallsByHeapId.concat(communicationCallsHeapId, policyCallsHeapId, billingCallsHeapId),
    temporaryId: onLoadCallsByHeapId,
    faq: onLoadCallsByHeapId,
    payment: onLoadCallsByHeapId.concat(billingCallsHeapId, policyCallsHeapId, paymentCallsHeapId)
};

const pageSpecificHeaderAlertHeapIds = {
    homepage: homepageHeaderAlertHeapId,
    documents: documentHeaderAlertHeapId
};

export const ComponentDisplay = {
    supress: {
        // hides header and footer of app
        margins: [
            '/temporaryId',
            '/documents/view'
        ],
        // hides mega menu only
        navigation: [
          '/esign',
          '/account/login',
          '/account/logout',
          '/account/unavailable',
          '/account/createnew',
          '/account/create',
          '/account/forgotusername',
          '/account/forgotpassword',
          '/account/changepassword',
          '/account/resetpassword',
          '/account/checkemail',
          '/account/tryagain',
          '/account/error',
          '/billing/guest-payment',
          '/pagemissing',
          ''
        ],
        // hides agent card only
        agentCard: [
          '/esign',
          '/account/login',
          '/account/logout',
          '/account/unavailable',
          '/account/createnew',
          '/account/create',
          '/account/forgotusername',
          '/account/forgotpassword',
          '/account/changepassword',
          '/account/resetpassword',
          '/account/checkemail',
          '/account/tryagain',
          '/account/error',
          '/billing/guest-payment',
          '/pagemissing',
          ''
        ],
        // hides welcome, logout, help
        headerInfo: [
          '/account/login',
          '/account/logout',
          '/account/unavailable',
          '/account/createnew',
          '/account/create',
          '/account/error',
          '/account/forgotusername',
          '/account/forgotpassword',
          '/account/changepassword',
          '/account/resetpassword',
          '/account/checkemail',
          '/account/tryagain',
          '/billing/guest-payment',
          '/pagemissing'
        ],
        // hides idle timer on unauth pages
        idleTimer: [
          '/account/login',
          '/account/logout',
          '/account/createnew',
          '/account/create',
          '/account/error',
          '/account/forgotusername',
          '/account/forgotpassword',
          '/account/changepassword',
          '/account/resetpassword',
          '/account/unavailable',
          '/account/checkemail',
          '/account/tryagain',
          '/billing/guest-payment',
          '/pagemissing'
        ],
        unauth: [
          '/account/changepassword',
          '/account/createnew',
          '/account/create',
          '/account/error',
          '/account/forgotpassword',
          '/account/forgotusername',
          '/account/login',
          '/account/logout',
          '/account/resetpassword',
          '/account/unavailable',
          '/account/checkemail',
          '/account/tryagain',
          '/billing/guest-payment',
          '/pagemissing'
        ],
        headerBottomMargin: [
          '/account/createnew',
          '/account/create'
        ],
        footerTopMargin: [
          '/account/createnew',
          '/account/create'
        ]
    },
    display: {
        // shows system alert notifications on page via NotificationSystemError heap Id
        notifications: [
            { url: '/homepage', heapIds: pageSpecificHeapIds.homepage },
            { url: '/billing', heapIds: pageSpecificHeapIds.billing },
            { url: '/policy', heapIds: pageSpecificHeapIds.policy },
            { url: '/esign', heapIds: pageSpecificHeapIds.esign },
            { url: '/documents', heapIds: pageSpecificHeapIds.documents },
            { url: '/profile', heapIds: pageSpecificHeapIds.profile },
            { url: '/lowmileage', heapIds: pageSpecificHeapIds.lowMileage },
            { url: '/communication', heapIds: pageSpecificHeapIds.communication },
            { url: '/temporaryId', heapIds: pageSpecificHeapIds.temporaryId },
            { url: '/faq', heapIds: pageSpecificHeapIds.faq },
            { url: '/payment', heapIds: pageSpecificHeapIds.payment },
        ],
        headerAlerts: [
            { url: '/homepage', heapIds: pageSpecificHeaderAlertHeapIds.homepage },
            { url: '/documents', heapIds: pageSpecificHeaderAlertHeapIds.documents }
        ]
    }
};
