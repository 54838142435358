<div class="eft-input">
  @if (showSpinner) {
  <div id="statusPageSpinner" class="spinner-card">
    <app-spinner></app-spinner>
  </div>
  }

  @if (!showSpinner) {
  <div>
    @if (showEFTSelection) {
    <div id="eft-selection">
      <h3 lmdsHeading type="h3" id="eft-selection-title" class="header">
        Select a bank account to use
      </h3>
      <div class="radio-selection">
        <div class="radio-item" (click)="selectSessionEFTInstrument()" (keyup.enter)="selectSessionEFTInstrument()" tabindex="1">
          <input class="sco-radio-button top-align-margin" type="radio" name="Select this bank account for payment"
            role="radio" id="eft_select_session" [value]="isSessionEFTAccountSelected  ? 'selected' : 'unselected'"
            [checked]="isSessionEFTAccountSelected" tabindex="0">
          <div class="label">
            <label class="choice-selection sco-label" for="eft_select_session">
              <div class="card-display">
                Previously entered bank account: {{ EFTDisplay }}
              </div>
            </label>
          </div>
        </div>
        <div class="radio-item" (click)="selectNewEFTInstrument()" (keyup.enter)="selectNewEFTInstrument()" tabindex="1">
          <input class="sco-radio-button top-align-margin" type="radio" name="Use a new bank account for payment"
            role="radio" id="eft_select_new" [value]="isNewEFTAccountSelected  ? 'selected' : 'unselected'"
            [checked]="isNewEFTAccountSelected" tabindex="0">
          <div class="label">
            <label class="choice-selection sco-label" for="eft_select_new">
              <div class="card-display">
                Another bank account
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    }
    @if (showIFrameApp) {
    <div>
      @if (!iFrameUrl) {
      <div class="spinner-card mini-app">
        <app-spinner></app-spinner>
      </div>
      }
      @if (iFrameUrl) {
      <div>
        @if (!showEFTSelection) {
        <h3 lmdsHeading type="h3" id="eft-header" [ngClass]="{'recurring': isREFT}">
          Enter your checking/savings information
        </h3>
        }
        <iframe [src]="iFrameUrl | safe: 'resourceUrl'" frameborder="0" id="eftIframe" class="eft-iframe" [ngClass]="{'recurring': isREFT}"
          title="eft app" aria-label="eft app"></iframe>
      </div>
      }
    </div>
    }
    @if (showCAMControls) {
    <div class="cam-input-controls" [ngClass]="{'normal-position': isSessionEFTAccountSelected}">

      @if (isGuestPayment) {
      <button class="sco-button sco--outline cancel-button"
        [ngClass]="{'iframe-position': showIFrameApp}" (click)="cancelEFT()" name="Cancel changes" role="button" id="PWOL_eftCancel">
        Back
      </button>
      }

      @if (showEFTSelection && !showIFrameApp) {
        @if (!isGuestPayment) {
        <button class="sco-button sco--outline cancel-button" (click)="cancelEFT()" name="Cancel changes" role="button" id="MAP_eftCancel">
          Cancel
        </button>
        }
        <button class="sco-button sco--fill" (click)="continueEFT()" name="Continue to review payment details"
        role="button" [id]="nextButtonId">{{nextButtonText}}</button>
      }
      
      @if (showIFrameApp) {
      <div class="iframe-messages" [ngClass]="{'pwol': isGuestPayment}">
        <div class="find-account-number" [ngClass]="{'recurring': isREFT}">
          Have a check handy?
          <a id="routing-account-number" class="sco-link sco--inline" [routerLink]="[]"
            (click)="openRoutingAccountNumberModal()" role="link">See where to find your routing and account numbers.</a>
        </div>
        @if (!isREFT) {
        <div class="disclaimer-container">
          <div class="disclaimer-text">
            This step processes your bank information. You'll review your payment next.
          </div>
        </div>
        }
      </div>
      }
    </div>
    
      @if (isREFT) {
      <div id="authorization-disclaimer">
        <span>By clicking Authorize, I agree to the terms and conditions of the </span>
        <a class="authorization-link" (click)="openEFTAuthorizationModal()" [routerLink]="[]" role="button" name="View automatic bank withdrawal authorization">Automatic bank withdrawal authorization</a>
        <span>, giving Safeco Insurance authority to automatically withdraw payments from my bank account and to return any refunds due in the same manner.</span>
      </div>
      }
    }
  </div>
  }
</div>
