import { Result } from "./apiResult";
import { AccountOptionsPaymentMethod } from "./billingAccountOptions";
import { InstrumentIdRq } from "./payments";

export class AutoPayRq {
    instrumentIdRq: InstrumentIdRq;
    policyNumber: string;
}

export class AutoPayScheduleRq {
  monthlyDueDate: number;
  billingPlanFrequency: string;
  correlationId: string;
}

export class AutoPayRs extends Result {
    accountOptions: AccountOptionsPaymentMethod;
    error: AutoPayError;
    correlationId: string;
}

export class AutopayReviewRs extends Result {
  reviewBillingScheduleList: ReviewBillingSchedule[];
  correlationId: string;
}


export class AutoPayError {
    instrumentErrorCode: string;
    accountOptionsError: AccountOptionsError; 
}

export class AccountOptionsError {
    code: string;
    description: string;
    additionalDetails: string;
}

export class ReviewBillingSchedule {
  autopayBillDueDate: string;
  manualBillDueDate: string;
  autopayBillMinDueAmount: string;
  manualBillMinDueAmount: string;
  manualBillStatus: string;
  showAdditionalChargesPopover: boolean;
}
