import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

import { environment } from 'src/environments/environment';

import { IndicatorPages } from 'src/app/utilities/constants/statusIndicator';
import CAMStorage from 'src/app/utilities/constants/CAMStorage';

import Billing from 'src/app/utilities/models/billing';
import Policy from 'src/app/utilities/models/policy';

import { AnalyticsService } from 'src/app/utilities/services/analytics-service/analytics.service';
import { BillingService } from 'src/app/utilities/services/billing-service/billing.service';
import { CommonService } from 'src/app/utilities/services/common-service/common.service'
import { Cookies } from 'src/app/utilities/services/cookies-service/cookies.service';
import { PolicyService } from 'src/app/utilities/services/policy-service/policy.service';
import { UXService } from 'src/app/utilities/services/ux-service/ux.service';

import BillingMethods from 'src/app/utilities/methods/billing.methods';
import PolicyMethods from 'src/app/utilities/methods/policy.methods';
import { Features } from 'src/app/utilities/models/features';
import { getLOBNameByNumber } from 'src/app/utilities/constants/linesOfBusiness';

@Component({
  selector: 'app-account-select',
  templateUrl: './account-select.component.html',
  styleUrls: ['./account-select.component.scss']
})
export class AccountSelectComponent implements OnInit {
  @Input() isCenterAligned: boolean = false;
  @Input() pageName: string = '';
  @Input() type: string = '';
  @Output() isAccountSelected?: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() selectedAccountChange: EventEmitter<any> = new EventEmitter<any>();

  billingAccounts: Billing[] = [];
  features: Features = new Features();
  imgLoc: string = environment.imgLoc;
  indicatorPages = IndicatorPages;
  isExtendedRenewalDiscount: string = '';
  isPolicyEdit: boolean = false;
  isSingleAccount: boolean = false;
  policies: Policy[] = [];
  policyParam: string = '';
  selectAccounts: SelectAccount[] = [];
  selectedAccount: SelectAccount = new SelectAccount();
  showSelector: boolean = true;
  supressSelectedAcct: boolean = true;
  supressSelector: boolean = false;
  title: string = '';
  renewalCookie: string = null;

  constructor(
    private analyticsService: AnalyticsService,
    private billingService: BillingService,
    private commonService: CommonService,
    private cookies: Cookies,
    private policyService: PolicyService,
    private route: ActivatedRoute,
    private router: Router,
    protected uxService: UXService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  async ngOnInit() {
    this.renewalCookie = this.cookies.getRenewalCookie();
    await this.getFeatures();

    switch (this.type) {
      case 'policy': {
        this.title = 'policy';
        try {
          this.isPolicyEdit = (this.pageName === 'PolicyEdit');
          this.policies = await this.policyService.getPolicies();
          this.removeCanceledPolicies();
          this.policyParam = this.getPolicyParam();
          // if policy number is in query param and is a valid policy for account, set it in cache storage
          if (this.policyParam && this.policies.find(policy => policy.number === this.policyParam)) {
            CAMStorage.setItemInStorage(this.getStorageKey(), this.policyParam);
          }
          this.selectAccounts = this.buildPolicySelectAccounts(this.policies);
          this.selectedAccount.number = CAMStorage.getItemInStorage(this.getStorageKey());
        } catch (err) {
          console.log(err);
        }
        break;
      }
      case 'billing': {
        this.title = 'billing account';
        try {
          this.billingAccounts = await this.billingService.getBilling();
          this.billingAccounts = BillingMethods.getNonCanceledAccountsWithFoundPolicies(this.billingAccounts);
          this.selectAccounts = this.buildBillingSelectAccounts(this.billingAccounts);
          this.selectedAccount.number = CAMStorage.getItemInStorage(this.getStorageKey());
        } catch (err) {
          console.log(err);
        }
        break;
      }
      case 'renewal': {
        this.title = 'policy';
        this.isExtendedRenewalDiscount = this.route.snapshot.queryParamMap.get("renewDisc");

        if (this.renewalCookie) {
          // this.policies = await this.policyService.getSrcRenewalPolicies(60, 30, new Date());          // original method returned all renewal policies in T-60/+30 window
          this.policies = await this.policyService.getMarketingRenewalPolicies();
        } else {
          this.policies = await this.policyService.getRenewalPolicies();

          if (!this.isExtendedRenewalDiscount) {
            this.policies = PolicyMethods.getPreRenewalPolicies(this.policies);
          }
        }

        this.policies = this.policies.filter(policy => PolicyMethods.displayRenewalDiscounts(policy.lineOfBusiness));

        this.selectAccounts = this.buildPolicySelectAccounts(this.policies);
        this.selectedAccount.number = CAMStorage.getItemInStorage(this.getStorageKey());
        break;
      }
      default: {
        break;
      }
    }

    this.isSingleAccount = (this.selectAccounts.length <= 1);

    if (this.selectedAccount.number) {
      const findAcct = this.selectAccounts.find((account) => this.selectedAccount.number === account.number);
      if (findAcct) {
        this.selectedAccount.policies.push(...findAcct.policies);
      }
      this.selectAccount(this.selectedAccount);
    } else {
      if (this.isSingleAccount) {
        this.selectAccount(this.selectAccounts[0]);
        this.toggleView(true);
      }
    }
  }

  async getFeatures() {
    try {
      this.features = await this.commonService.getFeatures();
    } catch (err) {
      console.log(err);
    }
  }

  getPolicyParam(): string { // get policy number from query param
    return this.route.snapshot.queryParams['policy'];
  }

  removeCanceledPolicies() {
    if (this.isPolicyEdit) {
      this.policies = PolicyMethods.getActivePolicies(this.policies);
    }
    
    if (this.features && this.features.canceledPolicyDocsLaunched) {
      this.policies = PolicyMethods.removeCanceledPoliciesOverAyear(this.policies);
    } else {
      this.policies = PolicyMethods.getActivePolicies(this.policies, true);
    }
  }

  buildPolicySelectAccounts(policies: Policy[]): SelectAccount[] {
    const selectAccounts: SelectAccount[] = [];
    policies.forEach((policy) => {
      selectAccounts.push({
        number: policy.number,
        isRenewalView: PolicyMethods.isRenewalView(policy) || (this.renewalCookie && policy.isMarketingRenewal),
        policies: [{ lob: policy.policyType.toLowerCase(), nickname: policy.nickName, isCanceled: policy.isCanceled, isCanceledEffective: policy.isCanceledEffective, canceledEffectiveDate : policy.canceledEffectiveDate}]
      });
    });
    return selectAccounts;
  }

  buildBillingSelectAccounts(billingAccts: Billing[]): SelectAccount[] {
    const selectAccounts: SelectAccount[] = [];
    billingAccts.forEach((billing) => {
      const policyArray = [];
      billing.policyList = BillingMethods.getActiveOrCanceledWithBalancePolicies(billing);
      billing.policyList.forEach((policy) => {
        policyArray.push({ lob: getLOBNameByNumber(policy.lineOfBusiness).toLowerCase(), nickname: (policy.nickName) ? policy.nickName : "NickName" });
      });
      selectAccounts.push({ number: billing.number, policies: policyArray });
    });
    return selectAccounts;
  }

  getStorageKey(): string {
    if (this.router.url.split('?').length) {
      return this.router.url.split('?')[0] + '-' + this.type;
    }
    return this.router.url + '-' + this.type;
  }

  async selectAccount(account: any): Promise<void> {
    if (account && account.number) {
      CAMStorage.setItemInStorage(this.getStorageKey(), account.number);
      this.selectedAccount = { number: account.number, policies: account.policies };
      this.selectedAccountChange.emit(this.selectedAccount);
      this.sendToAnalytics(account);
      this.sendKeyToAnalytics(account);
    }
    this.toggleView();
  }

  toggleView(singleAcct?: boolean) {
    this.showSelector = (singleAcct) ? false : !this.showSelector;
    this.supressSelector = false;
    this.supressSelectedAcct = false;

    if (this.isAccountSelected) {
      this.isAccountSelected.emit(this.showSelector);
    }

    setTimeout(() => {
      this.supressSelector = !this.showSelector;
      this.supressSelectedAcct = this.showSelector;
      if (this.showSelector) {
        const element: HTMLElement = this.document.getElementById('select-an-account');
        element.focus();
      } else {
        this.commonService.setADATitleFocus();
      }
    }, 750);

    return this.showSelector;
  }

  keyDownSelectAccount(event: KeyboardEvent, acct: SelectAccount) {
    if (event.key === 'Enter') {
      this.selectAccount(acct);
    }
  }

  keyDownToggleView(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.toggleView();
    }
  }

  //Send heap event for callouts
  sendToAnalytics(acct: any) {
    let heapId;
    if (acct.isRenewal) {
      heapId = this.pageName + '_accountSelector_btn_Renewal';
    }
    if (heapId) {
      this.analyticsService.trackAnalytics(heapId);
    }
  }

  sendKeyToAnalytics(account: any): void {
    let analyticsKey: any = {};

    switch (this.type) {
      case 'policy':
        analyticsKey = { 'polNum': account.number };
        break;
      case 'billing':
        analyticsKey = { 'bilNum': account.number };
        break;
      case 'renewal':
        analyticsKey = { 'renNum': account.number };
        break;
    }

    this.analyticsService.trackHeapKey(analyticsKey);
  }
}

export class SelectAccount {
  number: string = '';
  policies: any[] = [];
  isRenewalView?: boolean = false;

  constructor() {
    this.number = '';
    this.policies = [];
    this.isRenewalView = false;
  }
}
