import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import Endpoints from 'src/app/utilities/constants/endpoints';
import { CommonService } from '../common-service/common.service';
import { RequestCache } from 'src/app/utilities/interceptors/request-cache.service';
import Preferences from '../../models/preferences';
import { ApiResponseStatus } from 'src/app/utilities/models/apiResponseStatus';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  preferencesObs: Observable<PreferencesResponse> = null;
  preferencesObsNew: Observable<PreferencesResponseNew> = null;
  updatePrefsObs: Observable<PreferencesResponse> = null;
  updatePrefsObsNew: Observable<PreferencesResponseNew> = null;
  userPreferences: Preferences = null;

  constructor(
    private http: HttpClient,
    private commonService: CommonService,
    private location: Location,
    private requestCache: RequestCache
    ) {
    //this.preferencesObs = this.http.get<PreferencesResponse>(Endpoints.cloudApi.preferences, { withCredentials: true }).pipe(share());
  }

  async getPreferences(): Promise<Preferences> {
    const errorHeapId: string = 'MMA-CloudServiceFailure|GetPreferences';
    const path: string = this.location.path();

    if (!this.preferencesObsNew) {
      this.preferencesObsNew = this.http.get<PreferencesResponseNew>(Endpoints.cloudApi.preferences, { withCredentials: true }).pipe(share());
    }

    return new Promise((resolve, reject) => {

      this.preferencesObsNew.subscribe({
        next: (response: PreferencesResponseNew) => {

          if (response?.status?.code === 200 && response?.result) {
            this.requestCache.cacheBust(Endpoints.cloudApi.preferences);
            this.userPreferences = response.result;
            resolve(this.userPreferences);

          }
          else {
            reject();
            this.commonService.setServiceFailureAlert(errorHeapId, path);
          }
      
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          this.requestCache.cacheBust(Endpoints.cloudApi.preferences);
          reject({ error: error });
        }
      });
    });
  }

  async updatePreferences(updatedPreferences?: Preferences): Promise<Preferences> {
    const errorHeapId: string = 'MMA-CloudServiceFailure|UpdatePreferences';
    const path: string = this.location.path();
    const preferencesRequest: Preferences = updatedPreferences ? updatedPreferences : this.userPreferences;

    this.updatePrefsObsNew = this.http.post<PreferencesResponseNew>(Endpoints.cloudApi.preferences, preferencesRequest, { withCredentials: true }).pipe(share());

    return new Promise((resolve, reject) => {

      this.updatePrefsObsNew.subscribe({
        next: (response: PreferencesResponseNew) => {
          if (response?.status?.code === 200 && response?.result) {
            this.requestCache.cacheBust(Endpoints.cloudApi.preferences);
            this.userPreferences = response.result;
            resolve(this.userPreferences);
          }
          else {
            reject();
            this.commonService.setServiceFailureAlert(errorHeapId, path);
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          this.requestCache.cacheBust(Endpoints.cloudApi.preferences);
          reject({ error: error });
        }
      });
    });
  }

  setUserPreference(prefKey: string, prefVal: any): void {
    let userPreferences = this.userPreferences ? this.userPreferences : new Preferences();
    userPreferences[prefKey] = prefVal;
    this.userPreferences = userPreferences;
  }
  
}

class PreferencesResponseNew {
  result: Preferences;   
  status: ApiResponseStatus;
}
class PreferencesResponse {
  result: {
    userPreferences: Preferences;
    status: number;
    message: string
  };
  status: {
    code: number;
    description: string;
    additionalDetails: string;
  }
}
