<app-modal *ngIf="isModalOpen" [paperlessTermsAndConditions]="paperlessTermsAndConditions"></app-modal>
<div class="app-root">
  <div *ngIf="!suppressTimer">
    <idle-timer></idle-timer>
  </div>
  <div class="header-alerts" *ngIf="showHeaderAlerts && displayHeaderAlerts.length">
    <div class="sco-container">
      <page-alerts [alerts]='displayHeaderAlerts'></page-alerts>
    </div>
  </div>
  <div class="header-container" *ngIf="!suppressHeader" [ngClass]="{'no-menu' : suppressNav, 'no-bottom-margin' : suppressBottomMargin}">
    <div class="header-top sco-container" role="banner">
      <div class="safeco-logo-container">
        <a *ngIf="logolinkauth" [routerLink]="url.homepage" role="link" aria-label="Navigate to homepage" data-heap-id="header_img_safecoLogo">
          <app-logo></app-logo>
        </a>
        <a *ngIf="!logolinkauth" href="{{logolinksafecocom}}" role="link" aria-label="Navigate to safeco.com" data-heap-id="header_img_safecoLogo">
          <app-logo></app-logo>
        </a>
      </div>
      <div class="header-info" *ngIf="!suppressHeaderInfo">
        <div class="welcome" data-dd-action-name="Accountholder name greeting">{{ greeting }}</div>
        <div class="menu-link">
          <a class="logout" [routerLink]="" (click)="accountService.logout()" (keypress)="onKeyPress($event)" tabindex="0" role="link" id="logoutLink">
            <app-icon name="lockOpen" labelA11y="Log out of account"></app-icon>Log out
          </a>
          <span class="pipe">|</span>
          <a class="help-icon" [routerLink]="" (click)="toggleHelpMenu()" (keypress)="onKeyPress($event)" tabindex="0" role="link" id="helpLink">
            <app-icon name="help" labelA11y="Have a question"></app-icon>Help
          </a>
          <app-search *ngIf="searchLaunched" class="app-search"></app-search>
        </div>
      </div>


 

    </div>

    <div *ngIf="!suppressHeaderInfo && !loading" id="helpMenu" tabindex="-1">
      <div [ngClass]="{'active': helpActive}" class="overlay" (click)="helpActive = !helpActive"  (keyup.enter)="helpActive = !helpActive" tabindex="-1"></div>
      <div [ngClass]="{'active': helpActive}" class="right-rail">
        <img class="close-icon text-link-close" src="{{imgLoc}}/icons/close.svg" id="closeHelp" (click)="toggleHelpMenu()" (keypress)="onKeyPress($event)" alt="close help" tabindex="0" height="14" width="14">
        <div class="help-links">
          <contact-us></contact-us>
        </div>
        <div class="help-links"><a id="help_faq" routerLink="{{faqLink}}" (click)="helpActive = !helpActive">Questions & answers</a></div>
      </div>
    </div>
    <div class="full-width-bar">
      <div class="sco-container app-mega-menu">
        <app-megamenu *ngIf="!suppressNav && !loading"></app-megamenu>
      </div>
    </div>
  </div>
  <div id="welcome-sm" class="welcome-sm" *ngIf="suppressNav && !suppressHeader && !suppressHeaderInfo">{{ greetingShort }}
  </div>
  <div id="welcome-sm" class="welcome-sm" [ngClass]="{'no-bottom-margin' : suppressBottomMargin}" *ngIf="suppressHeaderInfo">
  </div>
  <!--for header with only logo showing-->

  <div class='mobile-app' *ngIf='suppressHeader'></div>

  <div class="content sco-container" *ngIf="!showCookieMessage">
    <div class="alerts">
      <app-alerts></app-alerts>
    </div>
    <div *ngIf="!(suppressAgentCard || suppressHeader) && !loading" class="agent-card main-content"
      [ngClass]="{'show-card-sms': displaySMSAgentCard}">
      <app-agent-card></app-agent-card>
    </div>
    <div class="main-content" role="main">
      <div class="update-spinner-overlay" *ngIf="loading && isAuthPage">
        <app-spinner [spinnerLabel]="'Checking for account updates...'"></app-spinner>
      </div>
      <div *ngIf="!loading">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <!-- <div class="sco-container">
      <app-slack-feedback class="main-content" *ngIf="!suppressNav && !suppressMargins"></app-slack-feedback>
  </div> -->

  <div id="no-cookies" class="content sco-container" *ngIf="showCookieMessage">
    <div class="main-content" role="main">
      <h1 lmdsHeading type="h2-light" class="h1Spacing">
        <app-icon class="error-icon" name="errorAlert" color="error" size="32"></app-icon> Oops, this site requires cookies
      </h1>
      <div class="content">
        <div>{{cookieMessage}}</div>
      </div>
    </div>
  </div>

  <div *ngIf="!suppressFooter" class="footer-container" [ngClass]="{'no-top-margin' : suppressTopMargin}">
    <app-footer></app-footer>
  </div>
  
</div>
