import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import Endpoints from 'src/app/utilities/constants/endpoints';
import { Observable } from 'rxjs';
import AddCustomerStatus from 'src/app/utilities/models/addCustomerStatus';
import ChangePasswordStatus from 'src/app/utilities/models/changePasswordStatus';
import UsernameAvailableStatus from 'src/app/utilities/models/usernameAvailableStatus';
import { CommonService } from '../common-service/common.service';
import { Cookies } from 'src/app/utilities/services/cookies-service/cookies.service';
import { RequestCache } from 'src/app/utilities/interceptors/request-cache.service';
import { PhoneNumberPipe } from '../../pipes/phone-number/phone-number.pipe';
import { AuthUser } from '../../models/authUser';
import { AuthUserService } from 'src/app/authentication/services/auth-user/auth-user.service';

@Injectable({
  providedIn: 'root'
})

export class AccountService {
  changePasswordObs: Observable<ChangePasswordStatus> = null;
  changePasswordStatus: ChangePasswordStatus = null;
  selfRegObs: Observable<SelfRegResponse> = null;
  usernameObs: Observable<UsernameAvailableStatus> = null;
  emailObs: Observable<EmailResponse> = null;
  custRepsonse: AddCustomerStatus = null;
  passwordChangeRequiredObs: Observable<PasswordChangeRequiredStatus> = null;
  result: string;
  addCustomerObs: Observable<CreateAccountResponse> = null;
  ValidateAndCreateCustomerDataObs: Observable<ValidateAndCreateCustomerResponse> = null;
  partyIdObs: Observable<PartyIdAndStatusResponse> = null;

  constructor(
    private http: HttpClient,
    private requestCache: RequestCache,
    private commonService: CommonService,
    private cookies: Cookies,
    private phonePipe: PhoneNumberPipe,
    private authUserService: AuthUserService
  ) { }

  // cam-api
  selfRegistration(policyNumber: string, dateOfBirth: string, email: string, nameFirst: string, nameLast: string, partyId: string): Promise<SelfRegResponse> {
    this.requestCache.cacheBust(Endpoints.cloudApi.selfRegistration);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      }),
      withCredentials: true
    };

    const custInfo: any = {
      'policyNumber': policyNumber,
      'birthdate': dateOfBirth,
      'emailAddress': email,
      'givenName': nameFirst,
      'familyName': nameLast,
      'partyId': partyId
    };

    this.selfRegObs = this.http.post<SelfRegResponse>(Endpoints.cloudApi.selfRegistration, JSON.stringify(custInfo), httpOptions);

    return new Promise((resolve, reject) => {
      this.selfRegObs.subscribe({
        next: (response: SelfRegResponse) => {
          resolve(response);
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert('MMA-View-AccountServiceSystemError|createNewCustomer');
          reject({ error: error });
        }
      });
    });
  }

  // Get Party Id and Status - new API
  getPartyId(policyNumber: string, dateOfBirth: string, email: string, nameFirst: string, nameLast: string): Promise<PartyIdAndStatusResponse> {
    this.requestCache.cacheBust(Endpoints.cloudApi.getPartyId);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      }),
      withCredentials: true
    };
   
    const data: any = {
      policyNumber: policyNumber,
      birthDate: dateOfBirth,
      emailAddress: email,
      givenName: nameFirst,
      surName: nameLast,
      caller: 'self'
    }

    this.partyIdObs = this.http.post<PartyIdAndStatusResponse>(Endpoints.cloudApi.getPartyId, JSON.stringify(data), httpOptions);

    return new Promise((resolve, reject) => {
      this.partyIdObs.subscribe({
        next: (response: PartyIdAndStatusResponse) => {
          resolve(response);
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert('MMA-View-AccountServiceSystemError|getPartyId');
          reject(error);
        }
      });
    });
  }

  // cam-api
  agentRegistration(guid: string, email: string): Promise<ValidateAndCreateCustomerResponse> {
    const endpoint = Endpoints.cloudApi.agentRegistration;
    this.requestCache.cacheBust(endpoint);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      }),
      withCredentials: true
    };

    const custInfo: any = {
      'guid': guid,
      'email': email
    };

    this.ValidateAndCreateCustomerDataObs = this.http.post<ValidateAndCreateCustomerResponse>(endpoint, JSON.stringify(custInfo) , httpOptions);

    return new Promise((resolve, reject) => {
      this.ValidateAndCreateCustomerDataObs.subscribe({
        next: (response: ValidateAndCreateCustomerResponse) => {
          resolve(response);
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert('MMA-View-AccountServiceSystemError|addCustomerAgentAssist');
          reject({ error: error });
        }
      });
    });
  }


  // Name: ValidateAndCreateCustomer : Agent Assisted Registration
  // Purpose: Api call to get customer data from GUID table and from Party API and Create Auth0 and Profile DB account if match is found
  ValidateAndCreateCustomer(guid: string, email: string): Promise<ValidateAndCreateCustomerResponse> {  
    this.requestCache.cacheBust(Endpoints.api.addCustomerAgentAssist);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      }),
      withCredentials: true
    };
    const ValidateAndCreateCustomerDataReq = new HttpParams().set('guid', guid).set('emailAddress', email); 
    this.ValidateAndCreateCustomerDataObs = this.http.post<ValidateAndCreateCustomerResponse>(Endpoints.api.addCustomerAgentAssist, ValidateAndCreateCustomerDataReq, httpOptions);

    return new Promise((resolve, reject) => {
      this.ValidateAndCreateCustomerDataObs.subscribe({
        next: (response: ValidateAndCreateCustomerResponse) => {
          resolve(response);
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert('MMA-View-AccountServiceSystemError|addCustomerAgentAssist');
          reject({ error: error });
        }
      });
    });
  }

  // Name: createCustomer : AGENT Assisted
  // Purpose: Api call to: validate customer with DOB or policy # (umbrella), get profile data, create profile in CPEC, add policy, send welcome email
  createCustomer(userId: string, guid: string, dob: string, password: string, policy: string, birthcity: string, email: string): Promise<CreateAccountResponse> {
    this.requestCache.cacheBust(Endpoints.api.addCustomerAgentAssist);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      }),
      withCredentials: true
    };
    const addCustomerReq = new HttpParams().set('username', userId).set('dob', dob).set('guid1', guid).set('password', password).set('policy', policy).set('birthcity', birthcity).set('email', email);
    this.addCustomerObs = this.http.post<CreateAccountResponse>(Endpoints.api.addCustomerAgentAssist, addCustomerReq.toString(), httpOptions);

    return new Promise((resolve, reject) => {
      this.addCustomerObs.subscribe({
        next: (response: CreateAccountResponse) => { //this will have data from all server calls (or nulls) 
          resolve(response);
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert('MMA-View-AccountServiceSystemError|createCustomer');
          reject({ error: error });
        }
      });
    });
  }

  isUsernameAvailable(email: string): Promise<boolean> {
    //this.requestCache.cacheBust(Endpoints.api.getUsernameAvailable);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      }),
      withCredentials: true
    };
    const userEmail = new HttpParams().set('EmailAddress', email);
    this.usernameObs = this.http.post<UsernameAvailableStatus>(Endpoints.api.getUsernameAvailable, userEmail.toString(), httpOptions);

    return new Promise((resolve, reject) => {
      this.usernameObs.subscribe({
        next: (response: UsernameAvailableStatus) => {
          resolve(true);
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert('MMA-View-AccountServiceSystemError|isUsernameAvailable');
          reject({ error: error });
        }
      });
    });
  }

  async checkEmail(email: string): Promise<boolean> {
    this.requestCache.cacheBust(Endpoints.api.getUsernameAvailable);
    const userEmail = new HttpParams().set('EmailAddress', email);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
      }),
      withCredentials: true
    };
    
    this.emailObs = this.http.post<EmailResponse>(Endpoints.api.getUsernameAvailable, userEmail, httpOptions);

    return new Promise((resolve, reject) => {
      this.emailObs.subscribe({
        next: (response: EmailResponse) => {
          if (response && response.result && response.status.code === 0) {
            this.requestCache.cacheBust(Endpoints.api.sendCustomerEmail);
            resolve(response.result === "Y");
          } else {
            reject({ error: 'Non-zero status code' });
          }
        },
        error: (error) => reject({ error: error })
      });
    });
  }

  getAuthUser(): AuthUser {
    return this.authUserService.authUser;
  }

  getPostProcessStatus(): number {
    return this.authUserService.postProcessStatus;
  }

  async logout(): Promise<void> {
    localStorage.clear();
    sessionStorage.clear();
    // this.clearUserSessionRecording();
    this.cookies.clearCookie('MMAEsignRedirect');
    this.requestCache.cacheBust();

    this.authUserService.logout();
  }

  async getCustomerPhoneNumber(): Promise<string> {
    let phone: string = '';

    try {
      const authUser: AuthUser = this.getAuthUser()
      phone = (authUser) ? this.phonePipe.formatPhoneNumber(authUser.phoneNumber) : '';
    } catch(err) {
      // continue regardless of error
    }
    
    return phone;
  }

  clearUserSessionRecording(): void {
    if (<any>window) {
      const cs = (<any>window)._uxa || [];
      cs.push(["session:clear:visitor"]);
    }
  }
}

export default class PasswordChangeRequiredStatus {
  result: boolean;
  status: {
    code: number;
  };
}

class EmailResponse {
  result: string;
  status: {
    code: number;
  };
}

class CreateAccountResponse {
  result: {
    isValid: boolean;
    errorMsg: string;
    accountAddedStatus: number;
    policyAdded: string;
  }
  status: {
    code: number;
  };
}

class SelfRegResponse {
  code: number
}

class ValidateAndCreateCustomerResponse {
  result: {
    description: string
  }
  status: {
    code: number;
  };
}

class PartyIdAndStatusResponse {
  result: {
    id: string;
    statusMsg: string;
  }
  status: {
    code: number;
  };
}
