import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import Endpoints from 'src/app/utilities/constants/endpoints';
import { ApiResponseStatus } from 'src/app/utilities/models/apiResponseStatus';
import { AnalyticsService } from 'src/app/utilities/services/analytics-service/analytics.service';
import { CommonService } from 'src/app/utilities/services/common-service/common.service';
import { RequestCache } from 'src/app/utilities/interceptors/request-cache.service';
import { AutoPayRq, AutoPayRs, AutoPayScheduleRq, AutopayReviewRs } from '../../models/autoPay';

@Injectable({
  providedIn: 'root'
})

export class AutoPayService {
  autoPayEnrollObs: Observable<any> = null;
  autoPayScheduleObs: Observable<any> = null;
  reviewAutopayUnenrollObs: Observable<ReviewAutopayUnenrollRs> = null;

  constructor(
    private commonService: CommonService,
    private http: HttpClient,
    private requestCache: RequestCache,
    private location: Location,
    private analyticsService: AnalyticsService
  ) {

  }

  async reviewAutopayUnenroll(policyNumber: string): Promise<AutopayReviewRs> {
    const errorHeapId = 'MMA-View-NotificationSystemError|ReviewAutopayUnenroll'

    this.reviewAutopayUnenrollObs = this.http.get<ReviewAutopayUnenrollRs>(Endpoints.api.autopayReviewUnenroll(policyNumber), { withCredentials: true }).pipe(share());
    return new Promise((resolve, reject) => {
      this.reviewAutopayUnenrollObs.subscribe({
        next: (response: ReviewAutopayUnenrollRs) => {
          if (response && response.result && response.result.status === HttpStatusCode.Ok) {
            resolve(response.result);
          } else {
            this.requestCache.cacheBust(Endpoints.api.autopayReviewUnenroll(policyNumber));
            this.commonService.setServiceFailureAlert(errorHeapId, this.location.path());
            reject({ error: response });
          }
        },
        error: (error) => {
          this.requestCache.cacheBust(Endpoints.api.autopayReviewUnenroll(policyNumber));
          this.commonService.setServiceFailureAlert(errorHeapId, this.location.path());
          reject({ error: error });
        }
      });
    });
  }

  async updateAutoPaySchedule(policyNumber: string, autoPayScheduleRq: AutoPayScheduleRq): Promise<AutoPayRs> {
    let errorHeapId = 'MMA-View-NotificationSystemError|UpdateAutoPaySchedule'
    const path = this.location.path();
    const autoPayScheduleEndpoint = Endpoints.api.autopayScheduleUpdate(policyNumber);

    this.autoPayScheduleObs = this.http.post<any>(autoPayScheduleEndpoint, autoPayScheduleRq, { withCredentials: true }).pipe(share());
    return new Promise((resolve, reject) => {
      this.autoPayScheduleObs.subscribe({
        next: (response: AutoPayEnrollRs) => {
          this.requestCache.cacheBust(autoPayScheduleEndpoint);

          if (response && response.result && !response.result.error) {
            resolve(response.result);
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: response.result });
          }
        },
        error: (error) => {
          const errorReason = error?.statusText ? error?.statusText.replaceAll(' ', '') : "ServiceCallException"
          errorHeapId += `|${errorReason}`;
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  async enrollCreditCardToAutoPay(autoPayRq: AutoPayRq): Promise<AutoPayRs> {
    const errorHeapId = 'MMA-View-NotificationSystemError|EnrollToAutoPayCC'
    const path = this.location.path();
    const autoPayEnrollEndpoint = Endpoints.api.autoPayEnrollCC;

    this.autoPayEnrollObs = this.http.post<any>(autoPayEnrollEndpoint, autoPayRq, { withCredentials: true }).pipe(share());
    return new Promise((resolve, reject) => {
      this.autoPayEnrollObs.subscribe({
        next: (response: AutoPayEnrollRs) => {
          this.requestCache.cacheBust(autoPayEnrollEndpoint);

          if (response && response.result && !response.result.error) {
            resolve(response.result);
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: response.result });
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }

  async enrollEftToAutoPay(autoPayRq: AutoPayRq): Promise<AutoPayRs> {
    const errorHeapId = 'MMA-View-NotificationSystemError|EnrollToAutoPayEft'
    const path = this.location.path();
    const autoPayEnrollEndpoint = Endpoints.api.autoPayEnrollEFT;

    this.autoPayEnrollObs = this.http.post<any>(autoPayEnrollEndpoint, autoPayRq, { withCredentials: true }).pipe(share());
    return new Promise((resolve, reject) => {
      this.autoPayEnrollObs.subscribe({
        next: (response: AutoPayEnrollRs) => {
          this.requestCache.cacheBust(autoPayEnrollEndpoint);

          if (response && response.result && !response.result.error) {
            resolve(response.result);
          } else {
            this.commonService.setServiceFailureAlert(errorHeapId, path);
            reject({ error: response.result });
          }
        },
        error: (error) => {
          this.commonService.setServiceFailureAlert(errorHeapId, path);
          reject({ error: error });
        }
      });
    });
  }
}

export class AutoPayEnrollRs {
  result: AutoPayRs;
  status: ApiResponseStatus;
}

export class ReviewAutopayUnenrollRs {
  result: AutopayReviewRs;
}
