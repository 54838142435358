<div class="accordion-component">
  <button class="accordion" [ngClass]="{'hide-border': hideBorder}" (click)="toggleAccordion()" [attr.aria-expanded]="expanded" [attr.aria-controls]="id" [id]="'accordion_' + id" [attr.data-heap-id]="heapId">
    <div class="accordion-header" tabindex="-1">
      <div class="title">
        <app-icon *ngIf="titleIcon" [name]="titleIcon" size="24"></app-icon>
        <h3 lmdsHeading type="h6">{{title}}</h3>
      </div>
      <app-icon name="accordionCaretDown" size="16" color="active" [ngClass]="expanded ? 'expanded':'collapsed'"></app-icon>
    </div>
  </button>
  <div class="accordion-content" [id]="id" role="region" [attr.aria-labelledby]="'accordion_' + id">
    <div class="accordion-expanded" [ngClass]="{'no-margin': hideExpandMargin}" *ngIf="expanded">
      <ng-content *ngIf="contentLoaded"></ng-content>
      <div *ngIf="!contentLoaded">
        <app-spinner></app-spinner>
      </div>
    </div>
  </div>
</div>
